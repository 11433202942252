.cardsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Čtyři sloupce stejné šířky */
  padding: 20px;
}

.card {
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  padding: 10px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  margin: 0 5px 0 5px;
  /* margin-bottom: 10px; */
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
}

.cardIcon {
  width: 60px;
  height: 60px;
  margin: 0 auto 15px auto;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 24px;
  color: #ffffff;
}

.cardTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.cardDescription {
  font-size: 14px;
  color: #555555;
}

@media (max-width: 1200px) {
  .cardsContainer {
    grid-template-columns: repeat(2, 1fr); /* 3 karty pro větší tablety nebo menší desktop */
  }
  .card {
    margin-bottom: 10px;
  }
}
@media (max-width: 720px) {
  .cardsContainer {
    grid-template-columns: repeat(1, 1fr); /* 3 karty pro větší tablety nebo menší desktop */
  }
  .card {
    margin-bottom: 10px;
  }
}
