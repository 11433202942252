.imageGallery {
  height: 500px; /* Pevná výška hlavního obrázku */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Zabránění přesahu */
  border-radius: 10px;
  border: 2px solid transparent;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Přechod pro interakce */
  object-fit: cover; /* Přizpůsobení velikosti obrázku */
}

.imageGalleryImg {
  height: 100%; /* Obrázek se přizpůsobí výšce */
  object-fit: cover; /* Zajistí, že obrázek nebude deformovaný */
}

@media (max-width: 1200px) {
  .imageGallery {
    height: 500px; /* Menší výška pro mobilní zařízení */
  }
  .imageGalleryImg {
    height: 100%; /* Obrázky se přizpůsobí na mobilních zařízeních */
  }
}
@media (max-width: 720px) {
  .imageGallery {
    height: auto; /* Menší výška pro mobilní zařízení */
    max-height: 580px;
    margin: 0 2% 0 2%;
    border-radius: 5px;
    border: 0px solid transparent; /* Zelený rámeček */
  }

  .imageGalleryImg {
    height: 100%; /* Obrázky se přizpůsobí na mobilních zařízeních */
  }
}
