.readContent {
  max-width: 1250px;
  margin: auto;
}
@media (max-width: 1400px) {
  .readContent {
    /* max-width: none; */
    margin: 0 5% 0 5%;
  }
}
