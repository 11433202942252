.superTextMedium {
  font-family: 'Arial', sans-serif; /* Nahraďte názvem písma, pokud znáte jeho jméno */
  font-weight: 800;
  font-style: normal;
  font-size: 4vw;
  color: white;
  letter-spacing: normal;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.container {
  display: grid;
  grid-template-columns: 60% 40%;
  position: relative;
  /* padding: 100px 100px 100px 80px; */
  background-color: #006635;
  margin-top: 140px;

  color: white;
  z-index: 5;
}
.leftSide {
  /* background-color: #006635; */
  height: 30vh;
  align-self: center;
  padding-top: 10vh;
  padding-left: 10%;
  /* padding: 100px 100px 100px 80px; */
}
.rightSide {
  display: grid;
  height: -webkit-fill-available;
  background-color: rgb(117, 192, 68);
  padding: 180px 0 10% 30px;
  text-align: left;
}
.imageSize {
  bottom: 0px;
  right: 10px;
  position: absolute;
  object-fit: cover;
  height: 17vw;

  /* top: -20px; */
  /* right: -6px;
  width: 34vw;
  height: 22vh; */
}
.info {
  z-index: 1;
}
.info p {
  text-align: left;
  /* padding-right: 30vw; */
}

/* Media Query for smaller screens */
@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: 100%;
    padding: 5%;
  }

  .rightSide {
    position: sticky;
    width: 100%;
    padding: 5%;
    margin-top: 20px;
    border-radius: 5px;
  }
  .imageSize {
    right: -15px;
    width: auto;
    height: 31vh;
  }
  .superTextMedium {
    font-size: 2.5rem;
  }
  .leftSide {
    height: 34vh;
  }
  .info {
    padding-right: 30vw;
  }
}
