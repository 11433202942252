.iconPosition {
  position: fixed;
  display: inline-flex;
  flex-direction: column;
  bottom: 20%;
  left: 18px;
  z-index: 6;
  @media (max-width: 769px) {
    display: none;
  }
}

.iconTemplate {
  height: 18px;
  width: 18px;
  margin-top: 30px;
}
.iconTemplate:hover {
  margin-bottom: 2px;
  margin-top: 28px;
  /* color: rgba(117, 192, 68, 1); */
}

.index {
  z-index: 1;
  margin: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  /* transform: translate(-50%, -50%); */
  left: 0%;
  overflow: auto;
  /* border: 1px solid rgba(117, 192, 68, 1); */
  /* background-color: white; */

  background-color: rgba(0, 91, 154, 0.5);
}

.icon {
  height: 2vw;
  width: 2vw;
  margin-top: 5px;
}
.icon:hover {
  margin-right: -0.1vw;
  height: 2.1vw;
  width: 2.1vw;
  cursor: pointer;
}
.marginRight {
  margin-right: 8px;
  font-size: 2vw;
}
.closeBtn {
  display: flex;
  cursor: pointer;
  justify-content: right;
  float: inline-end;
  width: 20px;
  margin: 10px 10px 0 0;
}
.background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-top: 20%;
  width: 40%;
  height: 20%;
  /* margin: 20% 0 25% 0; */
  border: 1px solid rgba(117, 192, 68, 1);
  border-radius: 10px;
}
.centerDiv {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 91, 154, 0.5);
  text-align: center;
  z-index: 2;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 769px) {
  .iconTemplate {
    margin-top: 10px;
    bottom: 0px;
  }
  .iconPosition {
    left: 0;
  }
}
