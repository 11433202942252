body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.breadcrumbs {
  display: flex;
  /* align-items: center; */
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid #ddd;
}

.crumb {
  display: flex;
  align-items: center;
}

.crumb:not(:last-child)::after {
  content: '>';
  color: #777;
}

.crumb a {
  color: #777;
  text-decoration: none;
}

.crumb a:hover {
  /* text-decoration: underline; */
  color: #333;
}

.crumb li {
  padding: 0 10px 0 7px;
  height: 20px;
}
.lu-home {
  margin-right: 5px;
}

div.crumb .last-crumb:hover {
  color: #777;
  pointer-events: none;

  /* cursor: text; */
}
