.content {
  display: flex;
  justify-content: space-between;
  gap: 3vw;
  margin: 15px 0 15px 0;
}
.superTextMedium {
  /* -webkit-text-stroke: 1px black; */
  font-family: 'Arial', sans-serif; /* Nahraďte názvem písma, pokud znáte jeho jméno */
  font-weight: 800;
  font-style: normal;
  font-size: 3.5vw;
  color: white;
  letter-spacing: normal;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  /* text-transform: uppercase;  */
}
.imageSizeMedium {
  /* width: 100%; */
  max-height: 250px;
  width: auto;
  border-radius: 15px;
  margin-top: 30px;
}
.textMedium {
  font-family: 'DM Sans', sans-serif;
  line-height: 1.5;
}
/* .padding-top {
    padding-top: 100px;
  } */
.navLarge {
  font-family: 'DM Sans', sans-serif;
  font-size: 1rem;
  text-decoration: none;
  color: black;
  font-style: normal;
}
.noPadding {
  padding: 0 !important;
}
.reference {
  display: inline;
  background-color: rgb(61, 95, 37);
  height: 40px;
  width: 80px;
  margin: 20px;
  padding: 10px 5px 10px 5px;
  border-radius: 3px;
  border: 1px solid rgb(61, 95, 37);
  color: white;
  text-decoration: none;
  margin-left: 10px;
}
.reference:hover {
  color: #dae002;
  cursor: pointer;
}
.display {
  display: flex;
  align-items: center;
}
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 768px) {
  .content {
    display: grid;
    justify-items: center;
  }

  .reference:hover {
    display: inline;
    background-color: rgb(61, 95, 37);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
    color: white;
  }
  .superTextMedium {
    font-size: 2.5rem;
  }
  .imageSizeMedium {
    width: 100%;
    object-fit: cover;
    margin: 25px 0 25px 0;

    /* margin: 100px; */
  }
  .textMedium {
    font-family: 'DM Sans', sans-serif;
  }
}
