a {
  cursor: pointer;
}
body {
  color: #222;
}
img {
  max-width: 100%;
}
main {
  padding: 0px 16px 0 16px;
  max-width: 1920px;
  margin: 0 auto;
}

header {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
  align-items: center;
}
header a {
  text-decoration: none;
  color: inherit;
}
header a.logo {
  font-weight: bold;
  font-size: 1.5rem;
}
header nav {
  display: flex;
  gap: 15px;
}
div.post {
  /* display: grid;
  grid-template-columns: 1fr; */
  /* gap: 20px; */
  margin: 10px 0 30px 0;
  overflow-wrap: anywhere;
}

div.post div.texts h2 {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
}
div.post div.texts h2:hover {
  text-decoration: underline;
}
div.post div.texts a {
  text-decoration: none;
  color: inherit;
}
div.post div.info {
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
  color: #888;
  font-size: 0.7rem;
  font-weight: bold;
  display: flex;
  /* gap: 10px; */
}
div.post div.info a.author {
  color: #333;
}
div.post p.summary {
  margin: 12px 0;
  line-height: 1.5;
}

form.login,
form.register {
  max-width: 400px;
  margin: 0 auto;
}
input {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 7px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}
button {
  width: 100%;
  display: block;
  background-color: #555;
  border: 0;
  color: #fff;
  border-radius: 5px;
  padding: 7px 0;
  cursor: pointer;
}
form.login h1,
form.register h1 {
  text-align: center;
  margin-bottom: 20px;
}
div.post div.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  aspect-ratio: 1/1;
}
div.center-content {
  display: flex;
  justify-content: center;
}
div.center-content div.post-page {
  max-width: 846px;
}
div.post-page div.image {
  max-height: 200px;
  display: flex;
  overflow: hidden;
}
div.post-page div.image img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  /* height: 100%; */
  border-radius: 12px;
}

div.post-page a {
  color: #333;
  text-decoration: underline;
}
div.post-page h1 {
  text-align: center;
  margin: 10px 0 5px;
}

div.post-page time {
  text-align: center;
  display: block;
  font-size: 0.7rem;
  color: #aaa;
}
div.post-page div.author {
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.7rem;
  font-weight: bold;
}
div.post-page div.content p {
  line-height: 1.6rem;
}

div.edit-row {
  text-align: center;
  margin-bottom: 20px;
}
div.post-page a.edit-btn {
  background-color: #333;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: white;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
}
a svg {
  height: 20px;
}
div.display-card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 40px 24px;
}
@media screen and (max-width: 1920px) {
  div.display-card {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 768px) {
  div.display-card {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 375px) {
  div.display-card {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin: 0;
    padding: 0;
  }
}
