.borderFooter {
  display: flex;
  justify-content: center;
  width: 100%;
}

input {
  width: 100%;
  height: 35px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}
textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}
form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  margin: 0 10% 0 10%;
}

label {
  margin-top: 1rem;
}
input[type='submit'] {
  margin-top: 2rem;
  cursor: pointer;
  background: #006635;
  color: white;
  border: none;
}
input[type='submit']:hover {
  background: rgb(117, 192, 68);
}
.error {
  color: red;
  display: flex;
  align-items: center;
}
