.container {
  align-items: center;
}
.container_flex {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  @media (max-width: 769px) {
    grid-template-columns: 1fr 1fr;
    gap: 5%;
  }
}
.photo {
  height: auto;
  width: 100%;
  position: relative;
  border-radius: 5px; /*This is fucking awesome 205px*/
  /* transition: transform 0.5s;  */
}
.paragraph {
  width: 100%;
}
