* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.logo {
  -webkit-text-stroke: 0px black;
  font-size: 30px;
  color: white;
  height: 38px;
  padding-left: 25px;
}
.flex {
  display: flex;
  align-items: flex-end;
}
.quote {
  color: white;
  margin: 0 0 3px 5px;
}
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.content {
  max-width: 1250px;
  margin: auto;
}
.navbar {
  position: fixed;
  z-index: 5;
  width: 100%;
  padding: 25px 0;
  overflow: hidden;
  transition: all 0.3s ease;
}
.navbar.sticky {
  padding: 10px 0;
  background-color: rgba(61, 95, 37, 0.95);
}
.navbar .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
li {
  list-style: none;
}

.navbar .logo .nav {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.navbar .menuList {
  display: inline-flex;
}
.menuList li {
  list-style: none;
}
.menuList li .nav {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-left: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
}
.menuList li .nav:hover {
  color: #dae002;
}
.banner {
  position: relative;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  z-index: 4;
  /* z-index: 999; */
}

/* .about {
  padding: 40px 0;
}
.about .title {
  font-size: 35px;
  font-weight: 700;
}
.about p {
  padding-top: 20px;
  text-align: justify;
} */

.icon {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.icon.hide {
  display: none;
}
.icon.cancelBtn {
  position: absolute;
  right: 30px;
  top: 20px;
}
@media (max-width: 375px) {
  .quote {
    display: none;
  }
}
@media (max-width: 868px) {
  body.disabledScroll {
    overflow: hidden;
  }
  .icon {
    display: block;
  }
  .navbar .menuList {
    /* display: none; */
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 100%;
    max-width: 400px;
    background: rgba(117, 192, 68, 0.95);
    display: block;
    padding: 40px 0;
    text-align: center;
    transition: all 0.3s ease;
  }
  .navbar .content {
    margin: 0 11px 0 19px;
  }

  .navbar .menuList.active {
    left: 0%;
  }
  .navbar .menuList li {
    margin-top: 45px;
  }
  .navbar .menuList li .nav {
    font-size: 23px;
  }
}
