.readContent {
  max-width: 1250px;
  margin: auto;
}

.itemPlusMinus {
  display: flex;
  justify-items: center;
  margin: 10px 10px 10px 0;
}

.separateCode {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.spaceIcon {
  margin: 0 5px 0 0;
  flex-shrink: 0;
}
@media (max-width: 1400px) {
  .readContent {
    /* max-width: none; */
    margin: 0 5% 0 5%;
  }
  .separateCode {
    display: grid;
    grid-template-columns: 1fr;
  }
}
